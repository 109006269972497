import { convertArrToObj } from "shared/utils";
import { language } from "../language";

export const CH_INSTALLATION = "ch_installation";
export const HTML_PRINT = "";
export const TIME_DEBOUNCE_CALL_API = 600;
export const OTHER = "OTHER";
export const DEFAULT_PAGE = 1;

export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const DISABLED = "DISABLED";
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
export const STATUS = [
  {
    label: language.success,
    value: SUCCESS,
    color: "success",
  },
  {
    label: language.fail,
    value: FAILURE,
    color: "error",
  },
  {
    label: language.active,
    value: ACTIVE,
    color: "success",
  },
  {
    label: language.inactive,
    value: INACTIVE,
    color: "default",
  },
  {
    label: language.inactive,
    value: DISABLED,
    color: "default",
  },
];
export const STATUS_OBJ = convertArrToObj(STATUS, "value");

export * from "./responseCodeMapping";
export * from "./env";
export * from "./api";
export * from "./language";
export * from "./ga";
export * from "./permission";
export * from "./gender";
export * from "./user";
export * from "./announcement";
export * from "./helpDesk";
export * from "./chat";
export * from "./apartmentMeeting";
export * from "./rent";
export * from "./receipt";
export * from "./resident";
export * from "./buildingConfig";
