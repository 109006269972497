import * as BASE from './base';
import * as BUILDING_CONFERENCE from './buildingConference';

export const RESPONSE_MAPPING: {
  [key: string]: string;
} = {
  ...BASE,
  ...BUILDING_CONFERENCE,
};

export * from './base';
export * from './buildingConference';
