import notification from 'components/Notification';
import { language } from '../language';

export default function printHtml(html: string) {
  const mywindow = window.open('Print', '', '');
  if (mywindow) {
    mywindow.document.write(html);
    mywindow.document.close();
    mywindow.onload = () => {
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    };
  } else {
    notification.error({
      description: language.print_error,
      message: language.error,
    });
  }
}
