import get from 'lodash/get';
import { COOKIE_TAG } from '../constants/env';
import Cookie from '../utils/cookie';
import en from './json/en.json';
import vn from './json/vn.json';

const getLanguage = () => {
  const cache = Cookie.get(COOKIE_TAG);
  switch (cache.language) {
    case 'en':
      return en;
    case 'vi':
      return vn;
    default:
      return vn;
  }
};

export const getCacheLanguage = () => {
  const cache = Cookie.get(COOKIE_TAG);
  return cache?.language ?? 'vi';
};

export const language: {
  [key: string]: string;
} = getLanguage();

export const setLanguage = (languageInput: string) => {
  const cache = Cookie.get(COOKIE_TAG);
  Cookie.save(COOKIE_TAG, {
    ...cache,
    language: languageInput,
  });
};

export const languageBindArgs = (
  keyLanguage: string,
  data: {
    [key: string]: string;
  }
) => {
  const tmp = String(get(language, keyLanguage, ''));
  Object.keys(data).forEach((key) => {
    const expression = `{${key}}`;
    const regex = new RegExp(expression, 'i');
    tmp.replace(regex, data[key]);
  });
  return tmp;
};
