import moment from 'moment';
import { getCacheLanguage } from 'shared/language';
import { Cookie } from 'shared/utils';
import { COOKIE_TAG } from 'shared/constants';
import 'moment/locale/eu';
import 'moment/locale/vi';
import { ConfigProvider } from 'antd';
import enGB from 'antd/es/locale/en_GB';
import viVN from 'antd/es/locale/vi_VN';
import jaJp from 'antd/es/locale/ja_JP';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'components/Error';
import { ThemeProvider } from '@emotion/react';
import theme from 'shared/config/theme';
import { useFcm } from 'shared/helpers';
import GlobalContext from './globalContext';
import Routes from './routes';
import 'assets/icon.scss';
import 'assets/index.scss';
import 'assets/App.less';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';

const Root = () => {
  useFcm();

  useEffect(() => {
    const cache = Cookie.get(COOKIE_TAG);
    moment.locale(cache?.language ?? 'vi');
  }, []);

  const getLanguage = () => {
    if (getCacheLanguage() === 'en') return enGB;
    if (getCacheLanguage() === 'jp') return jaJp;
    return viVN;
  };

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider locale={getLanguage()}>
        <ErrorBoundary>
          <GlobalContext>
            <div style={{ height: '100%', overflow: 'hidden' }}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </div>
          </GlobalContext>
        </ErrorBoundary>
      </ConfigProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

