import { convertArrToObj } from 'shared/utils';
import { language } from '../language';

export const WIDGET_TYPE_VIEW = 'VIEW';
export const WIDGET_TYPE_FORM = 'FORM';

export const TEXT_FIELD = 'TEXT_FIELD';
export const TEXT_AREA = 'TEXT_AREA';
export const RADIO_GROUP = 'RADIO_GROUP';
export const CHECKBOX = 'CHECKBOX';
export const CHECKBOX_GROUP = 'CHECKBOX_GROUP';
export const DROPDOWN = 'DROPDOWN';
export const DATE_PICKER = 'DATE_PICKER';
export const TIME_PICKER = 'TIME_PICKER';
export const DATE_TIME_PICKER = 'DATE_TIME_PICKER';

export const LABEL = 'LABEL';
export const DASH_LINE = 'DASH_LINE';
export const DIVIDER = 'DIVIDER';

export const VALIDATE_REQUIRED = 'required';

export const QUESTION_TYPES = [
  {
    label: language.title,
    value: LABEL,
  },
  {
    label: language.dash_line,
    value: DASH_LINE,
  },
  {
    label: language.divider,
    value: DIVIDER,
  },
  {
    label: language.short_answer,
    value: TEXT_FIELD,
  },
  {
    label: language.text,
    value: TEXT_AREA,
  },
  {
    label: language.single_choice,
    value: RADIO_GROUP,
  },
  {
    label: language.multiple_choice,
    value: CHECKBOX_GROUP,
  },
  {
    label: language.select,
    value: DROPDOWN,
  },
  {
    label: language.date,
    value: DATE_PICKER,
  },
  {
    label: language.time,
    value: TIME_PICKER,
  },
  {
    label: language.date_time,
    value: DATE_TIME_PICKER,
  },
];

export const QUESTION_TYPE_OBJ = convertArrToObj(QUESTION_TYPES, 'value');

export const QUESTION_STATUS_SAVE = 'SAVE';
export const QUESTION_STATUS_ACTIVE = 'ACTIVE';
export const QUESTION_STATUS_INACTIVE = 'INACTIVE';

export const APARTMENT_MEETING_STATUS_SAVE = 'SAVE';
export const APARTMENT_MEETING_STATUS_ACTIVE = 'ACTIVE';
export const APARTMENT_MEETING_STATUS_COMPLETE = 'COMPLETED';
export const APARTMENT_MEETING_STATUS_ARR = [
  {
    label: language.draft,
    value: APARTMENT_MEETING_STATUS_SAVE,
  },
  {
    label: language.public,
    value: APARTMENT_MEETING_STATUS_ACTIVE,
    color: 'processing',
  },
  {
    label: language.complete,
    value: APARTMENT_MEETING_STATUS_COMPLETE,
    color: 'success',
  },
];
export const APARTMENT_MEETING_STATUS_OBJ = convertArrToObj(APARTMENT_MEETING_STATUS_ARR, 'value');

export const APARTMENT_MEETING_RESIDENT_JOIN = 'RESIDENT_JOIN';
export const APARTMENT_MEETING_RESIDENT_ANSWER = 'RESIDENT_ANSWER';

export const APARTMENT_MEETING_FORM_TYPE_OWNER_COMMITTEE = 'OWNER_COMMITTEE';
export const APARTMENT_MEETING_FORM_TYPE_AREA_COMMITTEE = 'AREA_COMMITTEE';
export const APARTMENT_MEETING_FORM_TYPE_GENERAL = 'GENERAL';
export const APARTMENT_MEETING_FORM_TYPES = [
  {
    value: APARTMENT_MEETING_FORM_TYPE_OWNER_COMMITTEE,
    label: language.owner_committee,
  },
  {
    value: APARTMENT_MEETING_FORM_TYPE_AREA_COMMITTEE,
    label: language.area_committee,
  },
  {
    value: APARTMENT_MEETING_FORM_TYPE_GENERAL,
    label: language.general,
  },
];
