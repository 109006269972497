import { RESIDENT_ROLE_HOUSEHOLDER } from 'shared/constants';

export interface IUnitModel {
  id: number;
  name: string;
  imageCover: string;
  unitGroupId?: number;
  householder: {
    id: number;
    name: string;
  };
}

export interface IUnitResponse {
  id: number;
  key: number;
  name: string;
  unitGroup: {
    imageCover?: string;
    id: number;
  };
  imageCover: string;
  residents: Array<{
    id: number;
    role: string;
    fullName: string;
  }>;
}

const UnitModel: (data?: IUnitResponse) => IUnitModel = (data) => ({
  id: data?.id ?? 0,
  key: data?.id ?? 0,
  name: data?.name ?? '',
  imageCover: data?.unitGroup?.imageCover ?? '',
  unitGroupId: data?.unitGroup?.id ?? 0,
  householder: {
    id: data?.residents?.find((resident) => resident.role === RESIDENT_ROLE_HOUSEHOLDER)?.id ?? 0,
    name: data?.residents?.find((resident) => resident.role === RESIDENT_ROLE_HOUSEHOLDER)?.fullName ?? '',
  },
});

export default UnitModel;
