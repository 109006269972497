import 'moment/locale/eu';
import 'moment/locale/vi';
import { Routes, Route } from 'react-router-dom';
import React, { Suspense } from 'react';
import Loading from 'components/Loading';
import { language } from 'shared/language';
import PermissionDeniedPage from 'components/PermissionDenied';

const Print = React.lazy(() => import('components/Print'));

const NormalLogin = React.lazy(() => import('containers/Auth/Login/LoginView'));

const Announcement = React.lazy(() => import('containers/Announcement'));

const HelpDesk = React.lazy(() => import('containers/HelpDesk'));

const RentalUnit = React.lazy(() => import('containers/RentalUnit'));

const ServiceFee = React.lazy(() => import('containers/ServiceFee'));

const ApartmentMeeting = React.lazy(() => import('containers/ApartmentMeeting'));

const ApartmentMeetingCreate = React.lazy(() => import('containers/ApartmentMeeting/CreateMeeting/CreateMeetingView'));

const ApartmentMeetingUpdate = React.lazy(() => import('containers/ApartmentMeeting/UpdateMeeting/UpdateMeetingView'));

const ApartmentMeetingDetail = React.lazy(() => import('containers/ApartmentMeeting/DetailMeeting/DetailMeetingView'));

const ApplicationComponent = React.lazy(() => import('containers/App'));

function RootRoutes() {
  return (
    <Suspense fallback={<Loading loading />}>
      <Routes>
        <Route path="/print" element={<Print />} />
        <Route path="/login" element={<NormalLogin />} />
        <Route path="/" element={<ApplicationComponent />}>
          <Route path="/application/:id/">
            <Route path="help-desk" element={<HelpDesk />} />
            <Route path="financial" element={<div>{language.financial}</div>} />
            <Route path="chat" element={<div>{language.chat}</div>} />
            <Route path="announcement" element={<Announcement />} />
            <Route path="information" element={<RentalUnit />} />
            <Route path="service-fee" element={<ServiceFee />} />
            <Route path="apartment-meeting" element={<ApartmentMeeting />} />
            <Route path="apartment-meeting/create" element={<ApartmentMeetingCreate />} />
            <Route path="apartment-meeting/:idApartmentMeeting" element={<ApartmentMeetingDetail />} />
            <Route path="apartment-meeting/:idApartmentMeeting/update" element={<ApartmentMeetingUpdate />} />
          </Route>
        </Route>
        <Route path="*" element={<PermissionDeniedPage code="404" description={language.not_found_page} />} />
      </Routes>
    </Suspense>
  );
}

export default RootRoutes;
