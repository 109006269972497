import { isSuccessCode } from 'shared/utils';
import { useEffect } from 'react';
import notification from 'components/Notification';
import { language } from '../language';
import { RESPONSE_MAPPING } from '../constants';

const useNotificationResponse = (
  code: number | undefined,
  codeLanguage: string | undefined,
  successConfig?: {
    message: string;
    description: string;
  } | undefined | null,
  callbackSuccess?: () => void
) => {
  useEffect(() => {
    if (code) {
      if (isSuccessCode(code)) {
        if (successConfig) {
          notification.success(successConfig);
        }
        callbackSuccess && callbackSuccess();
      } else {
        notification.error({
          message: language.error,
          description: RESPONSE_MAPPING[codeLanguage || ''] ?? RESPONSE_MAPPING.SOMETHING_WRONG,
        });
      }
    }
  }, [callbackSuccess, code, codeLanguage, successConfig]);
};

export default useNotificationResponse;
